@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5vh;
  cursor: auto;
  animation: slideUp 0.5s ease-out forwards;
  transition: background-color 0.5s ease-out;
  z-index: 20;
}

.footer a {
  margin: 0 10px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
}

.footer a:hover {
  color: gold;
  cursor: pointer !important;
}

.footer.scrolled {
  background-color: var(--scroll-footer-color); 
  transition: all 0.5s ease-out;
}


.icon{
  color: var(--footer-icon-color);
}