.layout-cards {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 3rem;
  background: var(--layout-cards-background);
  overflow: hidden; 
  position: relative; 
  padding: 8vh;
  z-index: 2;
}

.projects {
  position: relative;
  overflow: hidden; 
}

.projects-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}


.layout-cards .dim-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease; 
}



.layout-cards .closed-card img {
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: top; 
  padding: 4rem;
}


.grid-card-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: start;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 900;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: 'Tektur', sans-serif;
  color: var(--layout-cards-background);
}



.layout-cards .opened-card .project-card-description {
  text-align: center;
  padding: 20px;
  color: black;
  width: 100%;
  height: 100%;
  font-family: 'Tektur', sans-serif;
  overflow: auto;
}


.layout-cards .opened-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 60%; 
  height: 70%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: none;
  box-shadow: rgba(100, 100, 111, 0.966) 0px 7px 29px 0px;
  z-index: 20;
  overflow: auto;
}

.layout-cards .opened-card div {
  background: rgb(99, 99, 99);
  height: 80%; 
  width: 90%; 
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.layout-cards .closed-card {
  position: relative;
  background: var(--layout-cards-closed);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden; 
}


.project-card-description h3 {
  color: #eaeaea;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-size: 2rem;
}

.project-card-description h4 {
  color: #fff;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.project-card-description p {
  color: #ddd;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  padding: 3rem;
  line-height: 2rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-align: justify;
}
  
  .layout-cards .opened-card div:first-child {
    margin-left: 0;
  }
  
  
  .layout-cards .closed-card:nth-child(1) {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
  
  .layout-cards .closed-card:nth-child(2) {
    grid-column: 3;
    grid-row: 1;
  }
  
  .layout-cards .closed-card:nth-child(3) {
    grid-column: 1;
    grid-row: 2;
  }
  
  .layout-cards .closed-card:nth-child(4) {
    grid-column: 2 / span 2;
    grid-row: 2;
  }
  
  .layout-cards .cards-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 33rem;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .layout-cards .cards-container::-webkit-scrollbar {
    display: none;
  }


  @media screen and (max-width: 600px) {
    .layout-cards {
      grid-template-columns: 1fr; 
      grid-template-rows: repeat(4, auto); 
      gap: 1rem;
    }

    .layout-cards .opened-card {
      width: 90%;
      height: 80%; 
      padding: 1rem; 
    }

    .layout-cards .closed-card:nth-child(1) {
      grid-column: 1 ;
      grid-row: 1;
    }
    
    .layout-cards .closed-card:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }
    
    .layout-cards .closed-card:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }
    
    .layout-cards .closed-card:nth-child(4) {
      grid-column: 1;
      grid-row: 4;
    }

    .layout-cards .opened-card { 
      width: 90%;
    }

    .layout-cards .closed-card img {
      padding: 2rem;
    }


    .project-card-description h3 {
      color: #eaeaea;
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 1rem;
    }
    
    .project-card-description h4 {
      color: #fff;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-size: 0.8rem;
    }
    
    .project-card-description p {
      color: #ddd;
      font-size: 0.9rem;
      line-height: 1.4;
      margin-bottom: 2rem;
      padding: 0;
      line-height: 2rem;
      font-weight: 300;
      font-size: 0.75rem;
      text-align: justify;
    }

    .grid-card-title {
      font-size: 0.7rem;
    }
  }  