@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=PT+Mono&family=Tektur&display=swap');

.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10rem 10rem 10rem 10rem;
    background: var(--layout-cards-background);
    min-height: 100vh;
    justify-content: center;
    font-family: 'Tektur', sans-serif;
  }
  
  .contact h1 {
    margin-bottom: 4rem;
    color: white;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 1rem;
    margin-bottom: 3rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #27272700;
    color: white;
    font-family: 'Tektur', sans-serif;
  }

  .contact-form input::placeholder,
  .contact-form textarea::placeholder{
    color: white;
    opacity: 1;
  }


  .container {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
  

  .button-container button {
    font-family: 'Tektur', sans-serif;
    background-color: #000000;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    color: white;
    border-radius: 10px;
    letter-spacing: 2px;
    font-weight: 600;
  }
  
  .button-container button:hover {
    background-color: #abff81;
    color: #000000;
  }
  
  .letter {
    position: relative;
    display: inline-block;
    transition: transform 4s ease;
  }
  
  .button-container button:hover .letter {
    transform: translateY(0%);
    font-weight: bolder;
  }

  
  @media (max-width: 768px) {
    .contact-form {
      width: 90%;
    }

    .contact-form button {
        width: 100%;
    }

    .contact {
        padding: 0;
        align-items: center;
    }
  }
  