@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=PT+Mono&family=Tektur&display=swap');

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10; 
  font-family: 'Tektur', sans-serif;
  padding-top: 1%;
}

.navbar-container{
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 0 20px; 
}

.logo {
  color: var(--logo-color);
  display: flex;
  align-items: center;
  margin: 1rem;
  z-index: 5;
  transition: 0.8s ease-in-out;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: 0; 
  animation: fadeIn 1s ease forwards;
  animation-delay: 1s;
  cursor: pointer;
  font-size: 1.2rem;
}

.navbar-theme-toggle{
  color: var(--theme-toggle-color);
  margin-bottom: 10px;
  transition: 0.8s ease-in-out;
  animation: fadeIn 1s ease forwards;
  animation-delay: 1s; 
  cursor: pointer;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}


.navbar-menu {
  display: flex;
  justify-content: center; 
  position: absolute;
  left: 50%; 
  transform: translateX(-50%); 
  border-radius: 25px;
  transition: all 0.5s ease-out;
}

.navbar-menu.scroll{
  background-color: rgba(50, 50, 50, 0.889); 
  transition: all 0.5s ease-out;
}
.navbar-menu li{
  padding: 1rem;
  position: relative;
  cursor: pointer;
}

.navbar-menu li::before {
  z-index: -1;
}

li::marker {
  content: none;
}

.navbar-menu li .active::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -10px;
  width: calc(100% + 24px);
  height: calc(100% + 16px);
  background-color: var(--navbar-li-background-before);
  border-radius: 20px;
  transition: all 0.5s ease-out;
  z-index: -1;
}


.freeze-screen {
  overflow: hidden; 
  height: 100vh; 
}


.navbar-menu li .active {
  position: relative; 
}

.navbar-menu li a {
  display: block;
  color: var(--navbar-menu-text);
  position: relative; 
  z-index: 1; 
  text-decoration: none; 
  font-weight: 900;
}



.navbar-hamburger {
  display: none;
  color: var(--hamburger-color)
}

.navbar-theme-toggle-mobile {
  display: none;
  color: var(--theme-toggle-color);
  transition: 0.8s ease-in-out;
  animation: fadeIn 1s ease forwards;
  animation-delay: 1s; 
  cursor: pointer;
}



@media screen and (max-width: 600px) {
  .navbar-menu {
    visibility: hidden; 
    opacity: 0; 
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    transform: translateX(100%); 
    flex-direction: column; 
    background-color: rgb(50, 50, 50); 
    transition: transform 0.5s ease, visibility 0s linear 0.5s, opacity 0.5s linear; 
    padding-top: 60px; 
    align-items: center; 
    justify-content: start; 
    z-index: 20; 
    border-radius: 0;
  }

  .navbar-menu.active{
    visibility: visible; 
    opacity: 1; 
    transform: translateX(0); 
    transition: transform 0.5s ease, visibility 0s, opacity 0.5s linear; 
    display: flex;
    flex-direction: column;
    background-color: var(--navbar-active-color);
  }

  .navbar-theme-toggle{
    display: none;
  }

  .navbar-hamburger {
    display: block; 
    position: absolute;
    right: 20px; 
    top: 20px;
    z-index: 21; 
    color: var(--hamburger-color)
  }

  .navbar-menu li {
    padding: 15px; 
    text-align: center; 
    width: 100%; 
    margin: 30px;
    border-radius: 0;
  }

  .navbar-menu li a {
    color: white; 
    font-size: 18px; 
  }

  .navbar-theme-toggle-mobile {
    display: block; 
    color: white;
  }

  .navbar-theme-toggle {
    display: none; 
  }

  .logo{
    display: none;
  }
  
}